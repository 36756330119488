
import { defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { BindingConfig } from '@/types'
import { getUserBinding } from '@/api/user'
import { ERR_OK } from '@/api/config'
export default defineComponent({
  name: 'UserBinding',
  setup () {
    const showLatest = ref(true)
    const bindingInfo = reactive<BindingConfig>({})
    onBeforeMount(async () => {
      const { code, data } = await getUserBinding()
      if (code === ERR_OK) {
        ({
          email: bindingInfo.email,
          phone: bindingInfo.phone,
          password: bindingInfo.password
        } = data)
      }
    })
    return { showLatest, bindingInfo }
  }
})
